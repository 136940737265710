.button {
  padding: 10px 17px;
  background:url("/assets/images/primaryBtnone.png") no-repeat center;
  background-size: cover;
  border: 1px solid var(--activeColor);
  border-radius: 5px;
 position: relative;
 @media (max-width:1199px) {
  padding: 10px 16px;
 }
 &:hover{
  background:none;
  background-size: cover !important;
  
 }
 :global(.MuiTypography-root){
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
 color: var(--white);
 text-transform: none;
 @media (max-width:1199px) {
  font-size: 14px;
 }
 @media (max-width:599px) {
  font-size: 14px;
 }
 @media (max-width:374px) {
  font-size: 11px;
 }
 strong{
  font-weight: 700;
  @media(max-width:575px){
    display: none;
  }
 }
 }
:global(.MuiSvgIcon-root){
font-size: 27px;
@media (max-width:1199px) {
  font-size: 20px;
 }
 }
//  &::before{
// content: "";
// position: absolute;
// top: 0;
// left:0%;
// width: 385.9px;
// height: 227.43px;
// background: radial-gradient(64.69% 65.27% at 34.75% 35.91%, #E3E3E3 0%, #CBCBCB 6%, #959595 19%, #676767 33%, #424242 47%, #252525 60%, #101010 74%, #040404 87%, #000000 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
// background-blend-mode: color-dodge;
// mix-blend-mode: color-dodge;
// opacity: 0.5;
// border-radius: 5px;
// transform: rotate(-31.22deg);

//  }
}




